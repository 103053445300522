
























































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

// components
import Dialog from '@/components/modals/Dialog.vue'
import FilesList from '@/components/FilesList.vue'
import LabelDataRow from '@/components/LabelDataRow.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import VideoCustom from '@/components/VideoCustom.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import AuthModule from '@/store/modules/auth'
import ManagerCoursesModule from '@/store/modules/manager/courses'
// store
import { LessonLargeResource } from '@/store/types'

@Component({
  components: {
    Dialog,
    FilesList,
    LabelDataRow,
    ModalWrapper,
    VideoCustom,
  },
})
export default class LessonModal extends Mixins(NotifyMixin) {
  @Prop({ default: false })
  private visible!: boolean

  @Prop({ required: true })
  private lessonID!: number

  @Prop({ required: true })
  private monthID!: number

  @Prop({ required: true })
  private courseID!: number

  @Prop({ default: [] })
  private lessonsID!: number[]

  private get currentIndex() {
    if (this.lesson) {
      return this.lessonsID.indexOf(this.lesson.id)
    }
    return 0
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private lesson: LessonLargeResource | null = null

  private mounted () {
    this.fetchLesson(this.lessonID)
  }

  private fetchLesson (lessonID: number) {
    ManagerCoursesModule.fetchLesson({
      courseID: this.courseID,
      lessonID: lessonID,
      monthID: this.monthID,
    })
      .then((response: LessonLargeResource) => {
        this.lesson = Object.assign({}, response)
      })
      .catch((error: any) => {
        this.handleClose()
        this.notifyError(error)
      })
  }

  private handleClose () {
    this.lesson = null
    this.$emit('close')
    this.$emit('update:lessonID', null)
  }

  @Watch('lessonID')
  private watchLessonID (value: number, oldValue: number) {
    if (value && value !== oldValue) {
      this.fetchLesson(value)
    }
  }
}
